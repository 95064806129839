<template>
    <div>
        <nfBreadcrumb :breadList="breadList"></nfBreadcrumb>
        <div class='instrumentContent'>
            <div class='clearfix top'>
                <div class='fl devicePic'>
                    <el-image
                            style="width: 270px; height: 169px"
                            :src="list.imageUrl || 'http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan/trademarket/2019-12-05/9d28408b-c75f-49e3-a8e9-0fc97dbf75a2.png'"
                            fit="cover"></el-image>
                </div>
                <div class='fl contentFl'>
                    <div class='deviceName clearfix'>
                        <span class='first ellipsis fl'>{{list.name?list.name:'暂无数据'}}</span>
                        <span class='baseName fl'>{{list.baseName?list.baseName:'暂无数据'}}</span>
                    </div>
                    <div class='commonFont clearfix'>
                        <img class='fl' :src="require('@/assets/images/dataShare/sjhm@2x.png')" alt="">
                        <span @click="goToQrCode" class='fl border' v-if='list.contactNumber'>{{list.contactNumber.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")}}</span>
                        <img class='fl' :src="require('@/assets/images/dataShare/pl@2x.png')" alt="">
                        <span @click="goToQrCode" class='fl'>在线联系</span>
                    </div>
                    <div class='address clearfix'>
                        <img class='fl' :src="require('@/assets/images/dataShare/dd@2x.png')" alt="">
                        <span class='fl'>{{list.laboratoryProvince}}{{list.laboratoryCity}}{{list.laboratoryArea}}{{list.laboratoryAddress}}</span>
                    </div>
                    <div>
                        <button @click="goToQrCode">我要预约</button>
                    </div>
                </div>
            </div>
            <div class='bottom'>
                <div class='titleTab'><span>基本信息</span></div>
                <div class='content'>
                    <ul class='clearfix last'>
                        <li class='fl name'>依托单位</li>
                        <li class='fl des flex_length'>{{list.relyonCompany?list.relyonCompany:'暂无数据'}}</li>
                    </ul>
                    <ul class='clearfix last'>
                        <li class='fl name '>官网</li>
                        <li class='fl des flex_length'>{{list.deviceProductType?list.deviceProductType:'暂无数据'}}</li>
                    </ul>
                    <ul class='clearfix last'>
                        <li class='fl name'>实验室仪器</li>
                        <li class='fl des flex_length'>{{list.laboratoryInstrument?list.laboratoryInstrument:'暂无数据'}}</li>
                    </ul>
                    <ul class='clearfix last'>
                        <li class='fl name'>可开展项目</li>
                        <li class='fl des flex_length'>{{list.promoteItem?list.promoteItem:'暂无数据'}}</li>
                    </ul>
                    <ul class='clearfix last'>
                        <li class='fl name'>服务范围</li>
                        <li class='fl des flex_length'>{{list.serviceScope?list.serviceScope:'暂无数据'}}</li>
                    </ul>
                    <ul class='flex last'>
                        <li class='name'>介绍</li>
                        <li class='des flex_length' v-html='list.introduce?list.introduce:"暂无数据"'></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import Vue from 'vue';
import { Image } from 'element-ui';
Vue.use(Image);
export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
            list: [],
            breadList: [{
                name: '资源共享'
            }, {
                name: '实验室共享',
                path: '/labShare'
            }, {
                name: '详情'
            }]
        };
    },
    mounted() {
        if (this.$route.query && this.$route.query.id) {
            this.getEquipment(this.$route.query.id);
        }
    },
    methods: {
        async getEquipment(id) {
            const api = this.$fetchApi.laboratoryDetail.api + id;
            const data = await this.$fetchData.fetchPost('', api, "json");
            if (data.code === '200' && data.result) {
                this.list = data.result;
            }
        },
        goToQrCode() {
            this.$router.push({
                path: '/downLoad'
            });
        }
    }
};
</script>
<style lang="less" scoped>
@import url('./common.less');
    .bottom ul {
        justify-content: center;
        background: #fafafa
    }
   .instrumentContent  .last{
        display: flex;
        .name{
            vertical-align: middle;
            border-right:none;
        }
        .des{
            padding-right:30px;
            background: #fff;
            border-left:solid 1px #e3e3e3;
            line-height: 30px;
        }
    }
</style>